<template>
  <main class="vr-container">
    <section class="section-wrapper">
      <div class="section-title-wrapper secondary-title">
        <div class="section-title red-title">
          WEB AR/VR/GIS DEMOS
          <span class="section-title-bar"></span>
        </div>
      </div>
      <p>We do mixed reality and geospatial apps on the web.</p>
      <div class="vr-showcase-wrapper">
        <div class="vr-showcase-item">
          <a target="_blank" href="#">
            <img
              src="@/assets/media/mockups/bctech_iphonexspacegrey_portrait.png"
              class="vr-showcase-image"
            />

            <div class="vr-showcase-description">
              VR Chatbot built for BCTECHSummit
              <a
                href="#"
                target="_blank"
              >
                <strong>Best with Google Cardboard</strong>
              </a>.
            </div>
          </a>
        </div>
        <div class="vr-showcase-item">
          <a target="_blank" href="http://bool.countable.ca/animatic/demo/00_drstrange.html">
            <img
              src="@/assets/media/mockups/drs_iphonexspacegrey_portrait.png"
              class="vr-showcase-image"
            />

            <div class="vr-showcase-description">
              WebVR comic panel concept built for Animatic Media - see inside
              this Dr. Strange comic.
            </div>
          </a>
        </div>

        <div class="vr-showcase-item">
          <a target="_blank" href="http://si.countable.ca">
            <img
              src="@/assets/media/mockups/si_iphonexspacegrey_portrait.png"
              class="vr-showcase-image"
            />

            <div class="vr-showcase-description">
              Winning entry in AI Justice competition. Smart legal guide and
              wayfinding app.
            </div>
          </a>
        </div>

        <div class="vr-showcase-item">
          <a target="_blank" href="https://countable-web.github.io/threejs-go/demos/mcd/">
            <img
              src="@/assets/media/mockups/poke_iphonexspacegrey_portrait.png"
              class="vr-showcase-image"
            />

            <div class="vr-showcase-description">
              McDonald's Pokemon-go style restaurant finder mixed reality.
              Developed in
              <a
                href="https://github.com/countable-web/threejs-go"
                target="_blank"
              >
                <strong>Three.js Go</strong>
              </a>.
              <br />This demo is 3rd person view like Pokemon Go, and so
              doesn't have VR Goggles enabled.
            </div>
          </a>
        </div>

        <div class="vr-showcase-item">
          <a target="_blank" href="http://bool.countable.ca/animatic/demo/01_nissan.html">
            <img
              src="@/assets/media/mockups/nis_iphonexspacegrey_portrait.png"
              class="vr-showcase-image"
            />

            <div class="vr-showcase-description">
              WebVR car color chooser built for Animatic Media. This one
              requires recent hardware (new phone or VR headset) for VR mode due
              to high poly count.
            </div>
          </a>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {};
</script>

<style></style>
